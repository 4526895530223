<div class="single-portfolio-area pt-80 game" *ngIf="traduzioni">
  <div class="container">
    <div class="row pb-20">
      <div class="col-12">
        <div class="single-portfolio-wrap">
          <div *ngIf="step == 1" class="boxRecuperoQrcode pointer" [routerLink]="'/' + mainService.language + '/33/' + traduzioni.sezioni[33].titoloUrl + '/qrcode'">
            <h4 [innerHTML]="traduzioni.messaggioRecuperoQrCode1"></h4>
            <div [innerHTML]="traduzioni.messaggioRecuperoQrCode2"></div>
          </div>
          <div class="single-port-content" *ngIf="step > 0 && step < 5">
            <div class="single-port-title">
              <h3 [innerHTML]="traduzioni.acquistaOraBiglietto + ' - ' + textStep"></h3>
            </div>
          </div>
          <div class="single-port-content" *ngIf="step == 5">
            <div class="single-port-title">
              <h3>{{traduzioni.prenotazioneSuccesso}}!</h3>
            </div>
          </div>
          <div class="mb-40 boxRiassunto" *ngIf="step > 1">
            <div class="mb-20"><h5><b><u>{{traduzioni.tuaPrenotazione}}</u></b></h5></div>
            <div *ngIf="step ==2" [innerHTML]="riepilogoRiga1" class="mb-10">
            </div>
            <div *ngIf="step > 2" [innerHTML]="riepilogoRiga2" class="mb-10">
            </div>
            <div *ngIf="step > 3" [innerHTML]="riepilogoRiga3">
            </div>
          </div>
          <div *ngIf="step == 1">
            <div class="row" >
              <div class="col-12">
                <form>
                  <div class="row">
                    <div class="form-group pb-20 col-12 col-sm-6" [formGroup]="myForm">
                      <label for="">{{traduzioni.quandoVuoiVenire}}?</label>
                      <input [class.is-invalid]="objInputData.invalid && objInputData.touched" type="date" [min]="today" class="form-control" formControlName="data" >
                      <small *ngIf="((objInputData.invalid && objInputData.touched) || objInputData.dirty) && objInputData.errors?.required">
                        {{traduzioni.campoObbligatorio}}
                      </small>
                      <small *ngIf="((objInputData.invalid && objInputData.touched) || objInputData.dirty) && objInputData.errors?.pattern">
                        {{traduzioni.valoreNonValido}}
                      </small>
                    </div>
                    <div class="form-group pb-20 col-12 col-sm-6" [formGroup]="myForm">
                      <label for="">{{traduzioni.quantiSiete}}?</label>
                      <input [class.is-invalid]="objInputPersone.invalid && objInputPersone.touched" type="number" class="form-control" formControlName="persone" >
                      <small *ngIf="((objInputPersone.invalid && objInputPersone.touched) || objInputPersone.dirty) && objInputPersone.errors?.required">
                        {{traduzioni.campoObbligatorio}}
                      </small>
                      <small *ngIf="((objInputPersone.invalid && objInputPersone.touched) || objInputPersone.dirty) && objInputPersone.errors?.pattern">
                        {{traduzioni.valoreNonValido}}
                      </small>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div class="row" >
              <div class="col-xl-8">
                <div class="single-shortcode-btn black-button medium-button mb-30">
                  <a class="btn-hover pointer" (click)="verificaDisponibilita()" [disabled]="loading" [innerHTML]="traduzioni.verificaDisponibilita" ></a>
                </div>
              </div>
            </div>

            <div class="row" *ngIf="mostraCalendario">
              <div class="col-12 pt-30">
                <div class="testo16 mb-3">{{traduzioni.noDIsponibilitaTickets}}</div>
                <full-calendar #calendar [options]="calendarOptions"></full-calendar>
              </div>
            </div>
            <div class="row" *ngIf="nessunRisultato">
              <div class="col-12">
                {{traduzioni.nessunRisultato}}
              </div>
            </div>
          </div>

          <div *ngIf="step == 2">
            <div class="row">
              <div class="col-12 testoSelezionaOrario pb-30">
                {{traduzioni.selezionareOrarioBiglietti}}
              </div>
              <div class="col-12" >
                <button *ngFor="let item of arrayOrari" class="btn btnOrario btn-success " [class.selected]="orarioSelezionato == item.id" type="button" (click)="selectOrario($event)" [value]="item.id"> {{item.denominazione}} </button>
              </div>
            </div>
            <form>
              <div class="row pt-40 form-group" [formGroup]="bigliettiForm">
                <div class="col-12 pb-20 rigaTariffa" *ngFor="let item of arrayTariffe">
                  <div style="width: 100px">
                    <input type="number" class="form-control" [min]="0" [formControlName]="'tariffa_' + item.id" (change)="aggiornaDati()" >
                  </div>
                  <div class="testoTariffa">
                    <div class="denominazione" [innerHTML]="item.denominazione + ' | ' + item.prezzoTxt"></div>
                    <div class="descrizione" [innerHTML]="item.descrizione"></div>

                  </div>
                </div>
              </div>
            </form>
            <div class="row pt-20 pb-10 ">
              <div class="col-12 totale">
                {{traduzioni.importoTotale}}: <b>{{totale}} €</b>
              </div>
            </div>
            <div class="row" *ngIf="orarioSelezionato == ''">
              <div class="col-12 testo16Alert">
                {{traduzioni.attenzioneSelezionareOrario}}
              </div>
            </div>
            <div class="row">
              <div class="col-12 testo16Alert">
                {{alertTickets}}
              </div>
            </div>
            <div class="row mt-30 mb-30">
              <div class="col-12" style="display: flex;">
                <div class="single-shortcode-btn dark-button medium-button mr-30">
                  <a class="btn-hover pointer" (click)="back()" >{{traduzioni.modificaRicerca}}</a>
                </div>
                <div class="single-shortcode-btn black-button medium-button" *ngIf="(bigliettiMancanti == 0) && (orarioSelezionato != '')">
                  <a class="btn-hover pointer" (click)="controlloDati()" >{{traduzioni.prosegui}}</a>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="step == 3">
            <div class="row">
              <div class="col-12">
                  <form>
                  <div class="row">
                    <div class="form-group pb-20 col-12 col-sm-6" [formGroup]="anagraficaForm">
                      <label for="">{{traduzioni.cognome}}</label>
                      <input [class.is-invalid]="objInputCognome.invalid && objInputCognome.touched" type="text" class="form-control" formControlName="cognome" >
                      <small *ngIf="((objInputCognome.invalid && objInputCognome.touched) || objInputCognome.dirty) && objInputCognome.errors?.required">
                        {{traduzioni.campoObbligatorio}}
                      </small>
                      <small *ngIf="((objInputCognome.invalid && objInputCognome.touched) || objInputCognome.dirty) && objInputCognome.errors?.pattern">
                        {{traduzioni.valoreNonValido}}
                      </small>
                    </div>
                    <div class="form-group pb-20 col-12 col-sm-6" [formGroup]="anagraficaForm">
                      <label for="">{{traduzioni.nome}}</label>
                      <input [class.is-invalid]="objInputNome.invalid && objInputNome.touched" type="text" class="form-control" formControlName="nome" >
                      <small *ngIf="((objInputNome.invalid && objInputNome.touched) || objInputNome.dirty) && objInputNome.errors?.required">
                        {{traduzioni.campoObbligatorio}}
                      </small>
                      <small *ngIf="((objInputNome.invalid && objInputNome.touched) || objInputNome.dirty) && objInputNome.errors?.pattern">
                        {{traduzioni.valoreNonValido}}
                      </small>
                    </div>
                  </div>
                  <div class="row">
                    <div class="form-group pb-20 col-12 col-sm-6" [formGroup]="anagraficaForm">
                      <label for="">EMAIL</label>
                      <input [class.is-invalid]="objInputEmail.invalid && objInputEmail.touched" type="email" class="form-control" formControlName="email" >
                      <small *ngIf="((objInputEmail.invalid && objInputEmail.touched) || objInputEmail.dirty) && objInputEmail.errors?.required">
                        {{traduzioni.campoObbligatorio}}
                      </small>
                      <small *ngIf="((objInputEmail.invalid && objInputEmail.touched) || objInputEmail.dirty) && objInputEmail.errors?.pattern">
                        {{traduzioni.valoreNonValido}}
                      </small>
                    </div>
                    <div class="form-group pb-20 col-12 col-sm-6" [formGroup]="anagraficaForm">
                      <label for="">{{traduzioni.telefono}}</label>
                      <input [class.is-invalid]="objInputTelefono.invalid && objInputTelefono.touched" type="text" class="form-control" formControlName="telefono" >
                      <small *ngIf="((objInputTelefono.invalid && objInputTelefono.touched) || objInputTelefono.dirty) && objInputTelefono.errors?.required">
                        {{traduzioni.campoObbligatorio}}
                      </small>
                      <small *ngIf="((objInputTelefono.invalid && objInputTelefono.touched) || objInputTelefono.dirty) && objInputTelefono.errors?.pattern">
                        {{traduzioni.valoreNonValido}}
                      </small>
                    </div>
                    <div class="form-group pb-20 col-12" [formGroup]="anagraficaForm">
                      <div style="display: flex">
                        <div>
                          <input [class.is-invalid]="objInputPrivacy.invalid && objInputPrivacy.touched" type="checkbox" class="" formControlName="privacy" >
                        </div>
                        <div>{{traduzioni.consensoRegistrazione}} <a target="_blank" href="https://www.museomultimedialemedievale.it/ita/4/privacy">https://www.museomultimedialemedievale.it/ita/4/privacy</a></div>
                      </div>
                      <small *ngIf="((objInputTelefono.invalid && objInputTelefono.touched) || objInputTelefono.dirty) && objInputPrivacy.errors?.required">
                        {{traduzioni.campoObbligatorio}}
                      </small>
                    </div>
                  </div>
                </form>
                <div class="row mt-30 mb-30">
                  <div class="col-12" style="display: flex;">
                    <div class="single-shortcode-btn dark-button medium-button mr-30">
                      <a class="btn-hover pointer" (click)="back()" >{{traduzioni.indietro}}</a>
                    </div>
                    <div class="single-shortcode-btn black-button medium-button" *ngIf="(bigliettiMancanti == 0) && (orarioSelezionato != '')">
                      <a class="btn-hover pointer" (click)="vaiAlPagamento()" [disabled]="loading" >{{traduzioni.vaiPagamento}}</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="step == 4">
            <div class="row">
              <div class="col-md-9 col-sm-12 col-xs-12 m-t">
                <ngx-paypal [config]="payPalConfig">loading...</ngx-paypal>
              </div>
            </div>
            <div class="row mt-30 mb-30">
              <div class="col-12" style="display: flex;">
                <div class="single-shortcode-btn dark-button medium-button mr-30">
                  <a class="btn-hover pointer" (click)="back()" >{{traduzioni.indietro}}</a>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="step == 5">
            <div class="row pb-50">
              <div class="col-12 m-t">
                <div class="testo16 mb-10" [innerHTML]="codicePrenotazione"></div>
                <div class="testo16" [innerHTML]="traduzioni.testoFinaleAcquisto"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
