import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LandingMTicketMShopComponent } from './landing-m-ticket-m-shop/landing-m-ticket-m-shop.component';
import { MTicketComponent } from './m-ticket/m-ticket.component';
import { RouterModule } from '@angular/router';
import { MShopElencoComponent } from './m-shop/m-shop-elenco/m-shop-elenco.component';
import { MShopSchedaComponent } from './m-shop/m-shop-scheda/m-shop-scheda.component';
import { FullCalendarModule } from '@fullcalendar/angular'; // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!

import { NgxPayPalModule } from 'ngx-paypal';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MTicketQrCodeComponent } from './m-ticket/m-ticket-qr-code/m-ticket-qr-code.component';
import { QRCodeModule } from 'angularx-qrcode';

FullCalendarModule.registerPlugins([ // register FullCalendar plugins
  dayGridPlugin
]);

@NgModule({
  declarations: [LandingMTicketMShopComponent, MTicketComponent, MShopElencoComponent, MShopSchedaComponent, MTicketQrCodeComponent],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    NgxPayPalModule,
    FullCalendarModule,
    QRCodeModule
  ]
})
export class MTicketMShopModule { }
