import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, forkJoin } from 'rxjs';
import { take, first } from 'rxjs/operators';
import { MainServiceService } from 'src/app/services/main-service.service';
import { MTicketService } from './m-ticket.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NotificationsService } from 'src/app/services/notification.service';
import { IPayPalConfig, ICreateOrderRequest } from 'ngx-paypal';
import { CalendarOptions, FullCalendarComponent } from '@fullcalendar/angular'; // useful for typechecking

@Component({
  selector: 'app-m-ticket',
  templateUrl: './m-ticket.component.html',
  styleUrls: ['./m-ticket.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class MTicketComponent implements OnInit {
  arrayTariffe: any[];
  traduzioni: any;
  myForm: FormGroup;
  bigliettiForm: FormGroup;
  anagraficaForm: FormGroup;
  loading = false;
  arrayOrari: any[];
  arrayAltriOrari: any[];
  orarioSelezionato = "";
  today = "";
  bigliettiMancanti: number = 0;
  bigliettiRichiesti: number = 0;
  totale: string;
  datiRichiesta: any = null;
  datiBiglietti: any = null;
  datiAnagrafica: any = null;
  config: any;
  step: number = 1;
  calendarOptions: CalendarOptions;
  alertTickets: string = "";
  nessunRisultato: boolean = false;
  mostraCalendario: boolean = false;
  riepilogoRiga1: string = "";
  riepilogoRiga2: string = "";
  riepilogoRiga3: string = "";
  textStep: string = "";
  codicePrenotazione: string = "";

  private step$: BehaviorSubject<number>;

  public payPalConfig?: IPayPalConfig;
  @ViewChild('calendar') calendarComponent: FullCalendarComponent;

  constructor(
    private service: MTicketService,
    private router: Router,
    private formBuilder: FormBuilder,
    public mainService: MainServiceService,
    private notificationsService: NotificationsService
  ) {

    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();

    this.today = yyyy + '-' + mm + '-' + dd;

    this.step$ = new BehaviorSubject<number>(0);

    this.myForm = this.formBuilder.group({
      data: ['', [Validators.required, Validators.pattern(this.mainService.datePattern)]],
      persone: ['', [Validators.required, Validators.pattern(this.mainService.numberPatter)]],
   });
  }

  setStep(newValue): void {
    this.step = newValue;
    this.step$.next(newValue);
  }

  getStep(): Observable<number> {
    return this.step$.asObservable();
  }

  ngOnInit(): void {
    forkJoin([
      this.service.getConfig(),
      this.mainService.getTraductionsSubject().pipe(first(data => data != null))
    ])
    .subscribe(
      ([config, traduzioni]) => {
        this.config = config;
        this.traduzioni = traduzioni;

        this.getStep().subscribe((value) => {
          this.textStep = this.traduzioni.passoDi.replace("%1", "<b>"+value+"</b>").replace("%2", "<b>4</b>");
        });

        this.setStep(1);
        this.mainService.showLoader(false);
      });

      const that = this;

      this.calendarOptions = {
        plugins: [  ],
        timeZone: 'Europe/Rome',
        locale: 'it',
        weekNumbers: true,
        weekNumberCalculation: 'ISO',
        nowIndicator: true,
        slotLabelFormat: {hour: '2-digit', minute: '2-digit'},

        handleWindowResize: true,
        height: 'auto',

        views: {
          dayGridMonth: {
            buttonText: ''
          }
        },

        initialView: 'dayGridMonth',

        buttonText: {
          today:    'Oggi'
        },

        eventClick: function(info) {
          that.verificaDisponibilita(info.event._def.extendedProps.dataTxt);
        },
      };
  }

  get objInputData() {
    return this.myForm.get('data');
  }

  get objInputPersone() {
    return this.myForm.get('persone');
  }

  get objInputCognome() {
    return this.anagraficaForm.get('cognome');
  }

  get objInputNome() {
    return this.anagraficaForm.get('nome');
  }

  get objInputTelefono() {
    return this.anagraficaForm.get('telefono');
  }

  get objInputEmail() {
    return this.anagraficaForm.get('email');
  }

  get objInputPrivacy() {
    return this.anagraficaForm.get('privacy');
  }


  back() {
    this.setStep(this.step - 1);
  }

  verificaDisponibilita(dataFromCalendar = null) {
    if (this.loading) {
      return;
    }
    if (!this.myForm.valid && !dataFromCalendar) {
      this.mainService.validateAllFormFields(this.myForm);
      return false;
    }
    this.loading = true;

    if (dataFromCalendar) {
      this.datiRichiesta = {data: dataFromCalendar, persone: this.bigliettiRichiesti};
      this.myForm.controls['data'].setValue(dataFromCalendar);
      window.scroll({
        top: 0,
        left: 0
      });

    }
    else {
      this.datiRichiesta = this.myForm.value;
    }

    this.service.verificaDisponibilita(this.datiRichiesta)
    .pipe(first())
    .subscribe(
      data => {
        this.arrayAltriOrari = [];
        this.arrayOrari = [];
        this.arrayTariffe = [];

        this.bigliettiRichiesti = this.datiRichiesta.persone;

        if (data.orari.length > 0) {
          let dArr = this.datiRichiesta.data.split("-");
          let dataIt = dArr[2]+ "/" +dArr[1]+ "/" +dArr[0];


          this.riepilogoRiga1 = this.traduzioni.riepilogoDataPersone.replace("%1", "<b>"+dataIt+"</b>").replace("%2", "<b>"+this.datiRichiesta.persone+"</b>")
          this.setStep(2);
          this.arrayOrari = data.orari;
          this.arrayTariffe = data.tariffe;

          this.bigliettiForm = this.formBuilder.group({
            data: [this.datiRichiesta.data,],
            orario: ['', [Validators.required]]
          });

          for (let key in this.arrayTariffe) {
            this.bigliettiForm.addControl('tariffa_' + this.arrayTariffe[key].id, new FormControl('0', Validators.required));
          }

          this.aggiornaDati();
        }
        else if (data.altriOrari && data.altriOrari.length > 0) {
          this.mostraCalendario = true;
          let dummy = [];
          for (let key in data.altriOrari) {
            dummy.push({title  : this.traduzioni.calendarioDisponibile,
              dataTxt  : data.altriOrari[key],
              start  : data.altriOrari[key],
            end  : data.altriOrari[key]
          })
          }
          this.arrayAltriOrari = dummy;
          this.calendarOptions.events = dummy;
        }
        else {
          this.nessunRisultato = false;
        }

        this.loading = false;
      },
      error => {
        this.notificationsService.show('error', '', error.error.message);
        this.loading = false;
      }
    );
  }



  selectOrario(event) {
    this.orarioSelezionato = event.target.getAttribute("value");
    this.bigliettiForm.controls['orario'].setValue(this.orarioSelezionato);
  }

  aggiornaDati() {
    let totale = 0;
    this.alertTickets = "";
    this.bigliettiMancanti = this.myForm.controls['persone'].value;
    for (let key in this.arrayTariffe) {
      let id = this.arrayTariffe[key].id;

      let prezzo = this.arrayTariffe[key].prezzo;
      let quantita = this.bigliettiForm.controls['tariffa_' + id].value;

      if (!Number.isInteger(quantita) || (quantita < 0)) {
        quantita = 0;
        this.bigliettiForm.controls['tariffa_' + id].setValue(quantita);
      }

      this.bigliettiMancanti -= quantita;
      totale += prezzo * quantita;
    }

    if (this.bigliettiMancanti < 0) {
      totale = 0;
      this.alertTickets = this.traduzioni.selezioneBigliettiEccessiva.replace("%1", this.myForm.controls['persone'].value);
    }
    else if (this.bigliettiMancanti > 0) {
      totale = 0;
      this.alertTickets = this.traduzioni.selezioneBigliettiInsufficiente.replace("%1", this.bigliettiMancanti).replace("%2", this.myForm.controls['persone'].value);
    }
    this.totale = totale.toFixed(2).replace(".", ",");
  }


  controlloDati() {
    if (this.loading) {
      return;
    }

    if (!this.bigliettiForm.valid) {
      this.mainService.validateAllFormFields(this.bigliettiForm);
      return false;
    }

    this.datiBiglietti = this.bigliettiForm.value;
    let dArr = this.datiRichiesta.data.split("-");
    let dataIt = dArr[2]+ "/" +dArr[1]+ "/" +dArr[0];

    this.riepilogoRiga2 = this.traduzioni.riepilogoDataOrarioTariffe.replace("%1", "<b>"+dataIt+"</b>").replace("%2", "<b>"+this.datiBiglietti.orario+"</b>").replace("%3", "<b>"+this.datiRichiesta.persone+"</b>");

    for (let key in this.arrayTariffe) {
      if (this.datiBiglietti['tariffa_' + this.arrayTariffe[key].id] != 0) {
        this.riepilogoRiga2 += "<br><b>" + this.datiBiglietti['tariffa_' + this.arrayTariffe[key].id] + "x</b> " + this.arrayTariffe[key].denominazione + " (" + this.arrayTariffe[key].prezzoTxt + ")";
      }
    }
    this.riepilogoRiga2 += "<br>" + this.traduzioni.importoTotale + ": <b>" + this.totale + "</b>";

    this.setStep(3);

    this.anagraficaForm = this.formBuilder.group({
      cognome: ['', [Validators.required]],
      nome: ['', [Validators.required]],
      telefono: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.pattern(this.mainService.emailPattern)]],
      privacy: ['', [Validators.required]],
    });
  }


  vaiAlPagamento() {
    if (this.loading) {
      return;
    }

    if (!this.anagraficaForm.valid) {
      this.mainService.validateAllFormFields(this.anagraficaForm);
      return false;
    }

    this.datiAnagrafica = this.anagraficaForm.value;

    this.riepilogoRiga3 = this.traduzioni.datiPersonali + "<br>" + this.traduzioni.cognome + ": <b>" + this.datiAnagrafica.cognome;
    this.riepilogoRiga3 += "<br>" + this.traduzioni.nome + ": <b>" + this.datiAnagrafica.nome;
    this.riepilogoRiga3 += "<br>" + this.traduzioni.telefono + ": <b>" + this.datiAnagrafica.telefono;
    this.riepilogoRiga3 += "<br>" + this.traduzioni.email + ": <b>" + this.datiAnagrafica.email;


    this.payPalConfig = null;
    this.initPaypalButton();

    this.setStep(4);
  }



  private initPaypalButton(): void {
    var totale = 0;
    var items = new Array();

    let that = this;

    for (let key in this.arrayTariffe) {
      let id = this.arrayTariffe[key].id;

      if (this.datiBiglietti['tariffa_' + id] && (this.datiBiglietti['tariffa_' + id] > 0)) {
        totale += this.arrayTariffe[key].prezzo * this.datiBiglietti['tariffa_' + id];

        items.push({
          name: this.arrayTariffe[key].denominazione,
          quantity: String(this.datiBiglietti['tariffa_' + id]),
          unit_amount: {
              currency_code: 'EUR',
              value: String(this.arrayTariffe[key].prezzo)
          }
        });
      }
    }

    this.payPalConfig = {
      currency: 'EUR',
      clientId: this.config.clientId,
      createOrderOnClient: (data) => <ICreateOrderRequest>{
        intent: 'CAPTURE',
        purchase_units: [{
          amount: {
              currency_code: 'EUR',
              value: String(totale),
              breakdown: {
                  item_total: {
                      currency_code: 'EUR',
                      value: String(totale)
                  }
              }
          },
          items: items
        }]
      },
      advanced: {
        commit: 'true',
      },
      style: {
        label: 'pay',
        layout: 'vertical'
      },


      onApprove: function(data, actions) {
        that.step = 0;
        that.mainService.showLoader(true);
        return fetch(that.mainService.SERVER_URL + '&action=mTicketPayment', {
            method: 'post',
            headers: {
              "content-type": "application/json",
            },
            body: JSON.stringify({
              orderID: data.orderID,
              datiBiglietti: that.datiBiglietti,
              datiAnagrafica: that.datiAnagrafica,
            }),
        }).then(function(res) {
          return res.json();
        }).then(function(orderData) {
            // Three cases to handle:
            //   (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
            //   (2) Other non-recoverable errors -> Show a failure message
            //   (3) Successful transaction -> Show confirmation or thank you

            // This example reads a v2/checkout/orders capture response, propagated from the server
            // You could use a different API or structure for your 'orderData'
            var errorDetail = Array.isArray(orderData.details) && orderData.details[0];

            if (errorDetail && errorDetail.issue === 'INSTRUMENT_DECLINED') {
                that.mainService.showLoader(false);
                that.step = 4;
                return actions.restart(); // Recoverable state, per:
                // https://developer.paypal.com/docs/checkout/integration-features/funding-failure/
            }

            if (errorDetail) {
                let msg = (errorDetail.description) ?? "";
                that.notificationsService.show('error', that.traduzioni.problemaPagamento, msg);
                if (orderData.noAvailability == "1") {
                  that.step = 1;
                }
                else {
                  that.step = 4;
                }
                that.mainService.showLoader(false);
                return;
            }

            // Successful capture! For demo purposes:

            that.finalize(orderData.codicePrenotazione);

            // Replace the above to show a success message within this page, e.g.
            // const element = document.getElementById('paypal-button-container');
            // element.innerHTML = '';
            // element.innerHTML = '<h3>Thank you for your payment!</h3>';
            // Or go to another URL:  actions.redirect('thank_you.html');
        });
    },

      onCancel: (data, actions) => {
        this.mainService.showLoader(false);
      },
      onError: err => {
        console.log("onError");
        console.log(err);
        this.mainService.showLoader(false);
      },
      onClick: (data, actions) => {
        console.log("onClick");
        if (data.fundingSource !== 'card') { // il pagamento con carta non avviene su finestra modale per cui non devo bloccare la finestra
//          this.mainService.showLoader(true);
        }
      },
    };
  }


  finalize(codicePrenotazione) {
    this.codicePrenotazione = this.traduzioni.tuoCodicePrenotazione.replace("%1", "<b>"+codicePrenotazione+"</b>");
    this.mainService.showLoader(false);
    this.step = 5;
    window.scroll({
      top: 0,
      left: 0
    });
  }
}
