<div class="single-portfolio-area pt-80 game" *ngIf="traduzioni">
  <div class="container">
    <div class="row pb-20">
      <div class="col-12">
        <div class="single-portfolio-wrap" *ngIf="!datiPrenotazione">
          <div class="single-port-content">
            <div class="single-port-title">
              <h3 [innerHTML]="traduzioni.titoloRecuperoQrCode"></h3>
              <div class="testo16 mt-20">{{traduzioni.istruzioniRecuperoQrCode}}</div>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
                <form>
                <div class="row">
                  <div class="form-group pb-20 col-12 col-sm-6" [formGroup]="recoverForm">
                    <label for="">Email</label>
                    <input [class.is-invalid]="objInputEmail.invalid && objInputEmail.touched" type="email" class="form-control" formControlName="email" >
                    <small *ngIf="((objInputEmail.invalid && objInputEmail.touched) || objInputEmail.dirty) && objInputEmail.errors?.required">
                      {{traduzioni.campoObbligatorio}}
                    </small>
                    <small *ngIf="((objInputEmail.invalid && objInputEmail.touched) || objInputEmail.dirty) && objInputEmail.errors?.pattern">
                      {{traduzioni.valoreNonValido}}
                    </small>
                  </div>
                  <div class="form-group pb-20 col-12 col-sm-6" [formGroup]="recoverForm">
                    <label for="">{{traduzioni.codicePrenotazione}}</label>
                    <input [class.is-invalid]="objInputCodice.invalid && objInputCodice.touched" type="text" class="form-control" formControlName="codicePrenotazione" >
                    <small *ngIf="((objInputCodice.invalid && objInputCodice.touched) || objInputCodice.dirty) && objInputCodice.errors?.required">
                      {{traduzioni.campoObbligatorio}}
                    </small>
                    <small *ngIf="((objInputCodice.invalid && objInputCodice.touched) || objInputCodice.dirty) && objInputCodice.errors?.pattern">
                      {{traduzioni.valoreNonValido}}
                    </small>
                  </div>
                </div>
              </form>
              <div class="row mt-30 mb-30">
                <div class="col-12" style="display: flex;">
                  <div class="single-shortcode-btn dark-button medium-button mr-30">
                    <a class="btn-hover pointer" [routerLink]="'/' + mainService.language + '/33/' + traduzioni.sezioni[33].titoloUrl" >{{traduzioni.indietro}}</a>
                  </div>
                  <div class="single-shortcode-btn black-button medium-button">
                    <a class="btn-hover pointer" (click)="recuperaQrCode()" [disabled]="loading" >{{traduzioni.conferma}}</a>
                  </div>
                </div>
              </div>
            </div>
          </div>

      </div>
      <div class="single-portfolio-wrap" *ngIf="datiPrenotazione">
        <div class="text-center">
          <qrcode [qrdata]="datiPrenotazione.securityHash" [errorCorrectionLevel]="'M'" width="300"></qrcode>
        </div>
        <div [innerHTML]="datiPrenotazione.html" class="testo16"></div>
      </div>
    </div>
  </div>
</div>
